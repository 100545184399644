<template>
  <nav class="navbar navbar-expand-lg main-navbar" :style="!drawer?'left:10px; ':''">
    <div  class="form-inline mr-auto">
      <ul class="navbar-nav mr-3">
        <li>
          <a href="#" data-toggle="sidebar" @click="_playSideBar()" class="nav-link nav-link-lg collapse-btn"><i class="fas fa-bars"></i></a>
        </li>
        <li>
          <a href="#" class="nav-link nav-link-lg fullscreen-btn">
            <i class="fas fa-expand"></i>
          </a>
        </li>
        <li>
         <!-- {{user? user.user_name : ""}}: <span style="font-weight:bold; color: darkgoldenrod"> {{ user ? currencyFormatUsd(user.balance)  : '' }}
        </span> -->
        <b style="font-size: larger">
            {{ user ? user.user_name : "" }}:
            <span style="font-weight: bold; color: darkgoldenrod" v-if="user && user.role_id == '612a3fcc1a1fcead8871e822'">
              {{ user ? currencyFormatUsd(user.companyBalances[0].balance) + "$, " + currencyFormatKm(user.companyBalances[1].balance) +
                  "៛" : "" }}
            </span>
            <span style="font-weight: bold; color: darkgoldenrod" v-else-if="user && (user.role_id == '6191dc644825e6f57ffa231f' ||  user.role_id == '6191dce94825e65937fa2340') ">
                {{ user ? currencyFormatUsd(user.agent_balance) : "" }}
                <span>{{ getUserCurrency() }}</span>
              </span>
            <span style="font-weight: bold; color: darkgoldenrod" v-else>
              {{ user ?  currencyFormatUsd(user.balance) : "" }}
              <span v-if="user && user.currency_type == 1">$</span>
              <span v-else>៛</span>
            </span>
           
          </b>
        </li>
      </ul>
    </div>
    <ul class="navbar-nav navbar-right">
      
        <a href="#" @click="onLogout" class="dropdown-item has-icon text-danger">
            Logout
          </a>
        
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: ["passUserInfo"],
  data: () => ({
  }),
  computed: {
    ...mapGetters("$_modules", {
      drawer: "getDrawer",
      user: "getUser",
    }),
  },
  methods: {
    _playSideBar(){
      this.UPDATE_DRAWER(!this.drawer)
    },  
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    getUserCurrency() {
      if (this.user.currency_type === 1) {
        return "$";
      } else if (this.user.currency_type === 2) {
        return "៛";
      } else if (this.user.currency_type === 3) {
        return "฿";
      }else{
        return this.user.currency_type;
      }
    },
    currencyFormatKm(num) {
        num = parseFloat(num);
        return parseInt(num).toLocaleString("en");
      },
    async onLogout() {
      let dealerId = this.$cookies.get("dealerId");
      if (dealerId) {
        let data = {
          barcode: dealerId.barcode,
        };
        await this.$request.post({
          url: "dealer/inActiveDealer",
          data: data,
        });
      }
      this.$cookies.remove("dealerId");
      this.$cookies.remove("token");
      this.$router.push("/login");
    },
    ...mapMutations("$_modules", ["UPDATE_DRAWER"]),
  },
};
</script>
<style scoped>
.font {
  font-family: 'SegoeUI', 'Koh Santepheap' !important;
}
</style>